const Noodl = require("@noodl/noodl-sdk");

function ProdiktFragment(props) {
  return <>{props.children}</>;
}

const ProdiktFragmentNode = Noodl.defineReactNode({
  name: "Prodikt Fragment",
  getReactComponent() {
    return ProdiktFragment;
  },
  inputProps: {},
  outputProps: {},
});

Noodl.defineModule({
  reactNodes: [ProdiktFragmentNode],
  nodes: [],
  setup() {
    //this is called once on startup
  },
});
